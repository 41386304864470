<template>
 <header class="main_menu transition_on">

    <div id="menu-segundo">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-6 blc-menu">
                <a class="logomenu" href="index.html">
                  <img src="/img/logo-naabsa.png" alt="Logo NAABSA">
                  <h1 style="margin: 0;z-index: -1; font-size: 0;">Marcasite Agência Digital</h1>
                </a>
              </div>

              <div class="col-6 blc-menu blc-segundo desktop transition_off">
                <router-link to="/perfil" class="btn-redondo btn-config">
                  <img src="/img/icon-engrenagem.png" alt="Engrenagem">
                </router-link>
              <!--   <a href="#" class="btn-redondo btn-notific">
                  <p>7</p>
                </a> -->
                <a href="#"  v-if="user.photo_url"  class="btn-redondo btn-ft-user">
                  <img v-if="user.photo_url" :src="user.photo_url" alt="Usuário">
                </a>

                <em class="txt">
                  <p>{{user.name}}<br><span>{{user.role}}</span></p>
                  <div class="sub">
                 <!--    <a href="#">
                      My profile
                    </a> -->
                    <a href="#"  @click.prevent="logout()">
                      Logout
                    </a>
                  </div>
                </em>
              </div>

              <div class="col-6 mobile" style="height: 85px; text-align: right;">
                <div class="hamburguer-bt mobile">
                  <div class="hamburguer_line hamburguer-top"></div>
                  <div class="hamburguer_line hamburguer-middle"></div>
                  <div class="hamburguer_line hamburguer-bottom"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="opt_menu" class="mobile">
      <div class="menu-lateral transition_on">
        <div class="item-menu user">
          <em class="inf-user txt transition_off">
            <img v-if="user.photo_url" :src="user.photo_url" alt="Foto Usuário">
            <p>{{user.name}}<br><span>{{user.role}}</span></p>
            <div class="sub">
           <!--    <a href="#">
                My profile
              </a> -->
              <a href="#" @click.prevent="logout()">
                Logout
              </a>
            </div>
          </em>
          <div class="btn-info">
            <a href="#" class="btn-redondo btn-config">
              <img src="/img/icon-engrenagem.png" alt="Engrenagem">
            </a>
         <!--    <a href="#" class="btn-redondo btn-notific">
              <p>7</p>
            </a> -->
          </div>                                    
        </div>

        <router-link to="/dashboard">
          <div class="item-menu ">
            <img src="/img/icon-statistics.png" alt="Statistics">
            <p>Statistics</p>
          </div>
        </router-link>

        <div class="item-menu subitens">
          <img src="/img/icon-comm.png" alt="Comm">
          <p>Comm</p>
          <span>></span>

          <ul>
            <router-link to="/cargas_tipos/adicionar">
              <li>New</li>
           </router-link>
           <router-link to="/cargas_tipos/listar">
              <li>List</li>
             </router-link>
          </ul>
        </div>

        <div class="item-menu subitens">
          <img src="/img/icon-lines.png" alt="Line-ups">
          <p>Line-ups</p>
          <span>></span>

          <ul>
            <router-link to="/lineups/adicionar"  v-if="!$root.isClient">
              <li>New</li>
           </router-link>
            <router-link to="/lineups/listar">
              <li>List</li>
             </router-link>
          </ul>
        </div>

        <div class="item-menu subitens">
          <img src="/img/icon-fields.png" alt="Fields of line-ups">
          <p>Fields of line-ups</p>
          <span>></span>

          <ul>
            <router-link to="/campos/afretadores">
              <li>Charterers</li>
              </router-link>

            <router-link to="/campos/agencias">
              <li>Agencies</li>
                </router-link>

                <router-link to="/campos/navios">
              <li>Vessels</li>
              </router-link>

               <router-link to="/campos/lineup_porto">
              <li>Ports of destination</li>
              </router-link>
          </ul>
        </div>

        <div class="item-menu subitens" v-if="$root.isAdmin || $root.isBasicAdmin">
          <img src="/img/icon-ports.png" alt="Ports">
          <p>Ports</p>
          <span>></span>

          <ul>
            <router-link to="/portos/adicionar">
              <li>New</li>
                </router-link>
          <router-link to="/portos/listar">s
              <li>List</li>
              </router-link>
          </ul>
        </div>

        <div class="item-menu subitens"  v-if="$root.isAdmin">
          <img src="/img/icon-grups.png" alt="Users">
          <p>Users</p>
          <span>></span>

          <ul>
               <router-link to="/usuarios/adicionar">
              <li>New</li>
                </router-link>
           <router-link to="/usuarios/listar">
              <li>List</li>
               </router-link>
         <router-link to="/usuarios/atividades">
              <li>Activities</li>
                </router-link>
          </ul>
        </div>
      </div>
    </div>
    <div class="fundo_menu"></div>
  </header>
<!--   <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="
        navbar-search navbar-search-dark
        form-inline
        mr-3
        d-none d-md-flex
        ml-lg-auto
      "
    >
      <div class="form-group mb-0">
    
      </div>
    </form>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        menu-classes="dropdown-menu dropdown-menu-right py-0 overflow-hidden"
      >
        <a href="#" slot="title" class="nav-link pr-0" @click.prevent>
          <div class="media align-items-center">
            <span
              class="avatar avatar-sm rounded-circle avatar-image"
              :style="{
                'background-image': `url('${profileImage}')`,
              }"
            >
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold">{{ title }}</span>
            </div>
          </div>
        </a>

        <template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <a href="/perfil" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>My profile</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="" @click.prevent="logout()" to="" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>
        </template>
      </base-dropdown>
    </ul>
  </base-nav> -->
</template>
<script>

  export default {
    data() {
      return {
        user:{},
        activeNotifications: false,
        showMenu: false,
        searchQuery: "",
        title: "Profile",
        avatar: null,
      };
    },
    async created() {
      this.$store.watch(
        () => this.$store.getters["profile/me"],
        (me) => {
          this.user = me;          
        }
        );
      await this.$store.dispatch("profile/me");
    },
    computed: {
      profileImage() {
        return this.avatar ? this.avatar : "/img/placeholder.jpg";
      },
    },
    methods: {
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      async logout() {
        try {
          this.$store.dispatch("logout");
        } catch (error) {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      },
    },
  };
</script>
