import Vue from "vue";
import router from "@/router";
import { VueAuthenticate } from "vue-authenticate";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
const url = process.env.VUE_APP_API_BASE_URL;
const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  tokenName: "access_token",
  loginUrl: "/oauth/token",
  getUser: "/me",
  registerUrl: "/register",
});

export default {
  state: {
    isAuthenticated:
      localStorage.getItem("vue-authenticate.vueauth_access_token") !== null,
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {},
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    isAdmin(state) {
      return state.user.role_id === 1;
    },
    isBasicAdmin(state) {
      return state.user.role_id === 2;
    },
    isOperator(state) {
      return state.user.role_id === 3;
    },
    isClient(state) {
      return state.user.role_id === 4;
    },
    user(state) {
      return state.user;
    },
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    },
    user(state, payload) {
      state.user = payload.user;
      localStorage.setItem("user", JSON.stringify(payload.user));
    },
  },

  actions: {
    getUser(context, payload) {
      return axios.get(`${url}/me`).then((response) => {
        context.commit("user", {
          user: response.data,
        });
      });
    },
    login(context, payload) {
      return vueAuth
        .login(payload.user, payload.requestOptions)
        .then((response) => {
          context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated(),
          });
          context.dispatch("getUser");
          router.push({ path: "/dashboard" });
        });
    },

    register(context, payload) {
      return vueAuth
        .register(payload.user, payload.requestOptions)
        .then((response) => {
          // context.commit("isAuthenticated", {
          //   isAuthenticated: vueAuth.isAuthenticated(),
          // });
          // router.push({ path: "/dashboard" });
        });
    },

    logout(context, payload) {
      return vueAuth.logout().then((response) => {
        context.commit("isAuthenticated", {
          isAuthenticated: vueAuth.isAuthenticated(),
        });
        router.push({ name: "login" });
      });
    },
  },
};
