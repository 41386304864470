var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"row infos"},[_c('div',{staticClass:"col-xl-4 col-lg-12"},[_c('div',{staticClass:"blc-dados"},[_c('img',{attrs:{"src":"/img/icon-ports.png","alt":"icone barco"}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.model.id ? 'New port' : 'Edit port')}})])])]),_c('div',{staticClass:"fundo"},[_c('div',{staticClass:"blc-inf-geral"},[_c('div',{staticClass:"blc-item"},[_c('h2',[_vm._v("Port")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 order-xl-1"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('h3',[_vm._v(" Information of port ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:" col-lg-7"},[_c('base-input',{attrs:{"alternative":"","required":"","label":"Name","placeholder":"port name","input-classes":"form-control-alternative","errors":_vm.errors.name},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c('div',{staticClass:"col-lg-3"},[_c('base-select',{attrs:{"alternative":"","required":"","label":"Bunker","options-key":"name","input-classes":"input","options":[
                  { name: 'ALLOWED ONLY AT BERTH' },
                  { name: 'NOT ALLOWED' },
                  { name: 'ALLOWED AT ANCHORAGE' },
                  { name: 'ALLOWED AT INNER ANCHORAGE / AT BERTH' } ],"errors":_vm.errors.bunker},model:{value:(_vm.model.bunker),callback:function ($$v) {_vm.$set(_vm.model, "bunker", $$v)},expression:"model.bunker"}})],1),_c('div',{staticClass:"col-lg-2"},[_c('base-input',{attrs:{"alternative":"","type":"number","required":"","label":"Order","placeholder":"","input-classes":"form-control-alternative","errors":_vm.errors.ordem},model:{value:(_vm.model.ordem),callback:function ($$v) {_vm.$set(_vm.model, "ordem", $$v)},expression:"model.ordem"}})],1)]),_c('h3',[_vm._v(" Terminals of this port "),_c('button',{staticClass:"submit btn-side",on:{"click":function($event){return _vm.addTerminal()}}},[_c('i',{staticClass:"fas fa-plus mr-2"}),_c('span',{staticClass:"btn-inner--text"},[_vm._v("Add terminal")])]),_vm._l((_vm.errors['terminais']),function(erro){return (_vm.errors['terminais'])?_c('div',{staticClass:"text-danger invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(erro)+" ")]):_vm._e()})],2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},_vm._l((_vm.model.terminais),function(terminal,terminalIndex){return _c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-header pt-4",staticStyle:{"border-bottom":"none"}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-lg-11"},[_c('base-input',{attrs:{"alternative":"","required":"","label":"","placeholder":"terminal name","errors":_vm.errors['terminais.' + terminalIndex + '.name']},model:{value:(terminal.name),callback:function ($$v) {_vm.$set(terminal, "name", $$v)},expression:"terminal.name"}})],1),_c('div',{staticClass:"col-lg-1"},[_c('div',{staticClass:"form-group has-label"},[_c('button',{staticClass:"btn base-button btn-danger w-100",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function () {
                        _vm.modal.delete.show = true;
                        _vm.modal.delete.terminalIndex = terminalIndex;
                                    // removeTerminal(terminalIndex, $event);
                                  }}},[_c('i',{staticClass:"fa fa-trash"})])])]),_c('div',{staticClass:"col-lg-6 mb-2"},[_c('base-input',{attrs:{"required":"","placeholder":"Loading/discharging rate","errors":_vm.errors['terminais.' + terminalIndex + '.rate']},model:{value:(terminal.rate),callback:function ($$v) {_vm.$set(terminal, "rate", $$v)},expression:"terminal.rate"}})],1),_c('div',{staticClass:"col-lg-6 mb-2"},[_c('base-input',{attrs:{"alternative":"","required":"","placeholder":"Av. w/d / b.w","errors":_vm.errors[
                              'terminais.' + terminalIndex + '.av_dw_bw'
                              ]},model:{value:(terminal.av_dw_bw),callback:function ($$v) {_vm.$set(terminal, "av_dw_bw", $$v)},expression:"terminal.av_dw_bw"}})],1),_c('div',{staticClass:"col-lg-4"},[_c('base-input',{attrs:{"alternative":"","required":"","placeholder":"Max draft","errors":_vm.errors[
                              'terminais.' + terminalIndex + '.max_draft'
                              ]},model:{value:(terminal.max_draft),callback:function ($$v) {_vm.$set(terminal, "max_draft", $$v)},expression:"terminal.max_draft"}})],1),_c('div',{staticClass:"col-lg-4"},[_c('base-input',{attrs:{"alternative":"","required":"","placeholder":"Air Draft","errors":_vm.errors[
                              'terminais.' + terminalIndex + '.draft_berth'
                              ]},model:{value:(terminal.draft_berth),callback:function ($$v) {_vm.$set(terminal, "draft_berth", $$v)},expression:"terminal.draft_berth"}})],1),_c('div',{staticClass:"col-lg-4"},[_c('base-input',{attrs:{"alternative":"","required":"","placeholder":"Loa","errors":_vm.errors['terminais.' + terminalIndex + '.loa']},model:{value:(terminal.loa),callback:function ($$v) {_vm.$set(terminal, "loa", $$v)},expression:"terminal.loa"}})],1),_c('div',{staticClass:"col-lg-12 my-2"},[_c('base-input',{attrs:{"alternative":"","required":"","placeholder":"Remark","errors":_vm.errors['terminais.' + terminalIndex + '.remark']},model:{value:(terminal.remark),callback:function ($$v) {_vm.$set(terminal, "remark", $$v)},expression:"terminal.remark"}})],1),_c('div',{staticClass:"col-lg-12"},[_c('multiple-select',{attrs:{"multiple":"","inputClasses":"input","close-on-select":false,"clear-on-select":false,"placeholder":"Comms of the terminal","options":_vm.cargasTipos,"optionsValue":"name"},model:{value:(terminal.cargas_ids),callback:function ($$v) {_vm.$set(terminal, "cargas_ids", $$v)},expression:"terminal.cargas_ids"}})],1)])]),_c('div',{staticClass:"card-body pt-0"},[_c('div',{staticClass:"card pt-0"},[_c('div',{staticClass:"card-header pb-0"},[_c('h3',{},[_vm._v(" Berths of this terminal "),_c('base-button',{staticClass:"submit btn-side",on:{"click":function($event){return _vm.addBerco(terminal)}}},[_c('i',{staticClass:"fas fa-plus mr-2"}),_c('span',{staticClass:"btn-inner--text"},[_vm._v("Add berth")])])],1)]),_c('div',{staticClass:"card-body pb-0"},_vm._l((terminal.bercos),function(berco,bercoIndex){return _c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-lg-11"},[_c('base-input',{attrs:{"alternative":"","required":"","placeholder":"berth name","errors":_vm.errors[
                              'terminais.' +
                              terminalIndex +
                              '.bercos.' +
                              bercoIndex +
                              '.name'
                              ]},model:{value:(berco.name),callback:function ($$v) {_vm.$set(berco, "name", $$v)},expression:"berco.name"}})],1),_c('div',{staticClass:"col-lg-1"},[_c('div',{staticClass:"form-group has-label"},[_c('button',{staticClass:"btn base-button btn-danger w-100 redondo",attrs:{"type":"submit ","disabled":_vm.loading},on:{"click":function($event){return _vm.removeBerco(terminal, bercoIndex)}}},[_c('i',{staticClass:"fa fa-trash"})])])])])}),0)])])])}),0)])])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 btn-final txt-right"},[_c('input',{staticClass:"submit-branco",attrs:{"type":"submit","value":"See listing"},on:{"click":function($event){return _vm.$router.push('/portos/listar')}}}),_c('button',{staticClass:"submit",attrs:{"type":"submit","value":"Save","disabled":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[(_vm.loading)?_c('i',{staticClass:"fa fa-spinner fa-spin"}):_vm._e(),_vm._v(" Save ")])])]),_c('modal',{attrs:{"show":_vm.modal.delete.show},on:{"close":function($event){return _vm.closeDeleteModal()}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Delete")]},proxy:true},{key:"footer",fn:function(){return [_c('button',{staticClass:"submit ",on:{"click":function($event){return _vm.removeTerminal(_vm.modal.delete.terminalIndex, $event)}}},[_vm._v(" Yes ")]),_c('button',{staticClass:"submit-verde",on:{"click":function () {
  _vm.closeDeleteModal();
  _vm.modal.delete.terminalIndex = null;
}}},[_vm._v(" No ")])]},proxy:true}])},[[_vm._v(" Do you really want to delete this terminal ? ")]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }