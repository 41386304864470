<template>
  <div class="main">
   <div class="row infos">
    <div class="col-xl-4 col-lg-12">
      <div class="blc-dados">
        <img src="/img/icon-ports.png" alt="icone barco">        
        <p v-html="model.id ? 'New port' : 'Edit port'"></p>
      </div>
    </div>
  </div>

  <div class="fundo">
    <div class="blc-inf-geral">
      <div class="blc-item">
        <h2>Port</h2>
        <div class="row">
          <div class="col-xl-12 order-xl-1">

            <form @submit.prevent>
              <h3 >
                Information of port
              </h3>

              <div class="row">
                <div class=" col-lg-7">
                  <base-input
                  alternative=""
                  required
                  label="Name"
                  placeholder="port name"
                  input-classes="form-control-alternative"
                  :errors="errors.name"
                  v-model="model.name"
                  />
                </div>
                <div class="col-lg-3">
                  <base-select
                  alternative=""
                  required
                  label="Bunker"
                  options-key="name"
                  input-classes="input"
                  :options="[
                  { name: 'ALLOWED ONLY AT BERTH' },
                  { name: 'NOT ALLOWED' },
                  { name: 'ALLOWED AT ANCHORAGE' },
                  { name: 'ALLOWED AT INNER ANCHORAGE / AT BERTH' },
                  ]"
                  :errors="errors.bunker"
                  v-model="model.bunker"
                  />
                </div>
                <div class="col-lg-2">
                  <base-input
                  alternative=""
                  type="number"
                  required
                  label="Order"
                  placeholder=""
                  input-classes="form-control-alternative"
                  :errors="errors.ordem"
                  v-model="model.ordem"
                  />
                </div>
              </div>

              <h3 >
                Terminals of this port
                <button class="submit btn-side"   @click="addTerminal()">    <i class="fas fa-plus mr-2"></i>
                <span class="btn-inner--text">Add terminal</span></button>
          
              <div
              class="text-danger invalid-feedback"
              style="display: block"
              v-for="erro in errors['terminais']"
              v-if="errors['terminais']"
              >
              {{ erro }}
            </div>
          </h3>

          <div class="row">
            <div class="col-md-12">
              <div
              class="card mb-3"
              v-for="(terminal, terminalIndex) in model.terminais"
              >
              <div class="card-header pt-4" style="border-bottom: none">
                <div class="form-row">
                  <div class="col-lg-11">
                    <base-input
                    alternative=""
                    required
                    label=""
                    placeholder="terminal name"
                    :errors="
                    errors['terminais.' + terminalIndex + '.name']
                    "
                    v-model="terminal.name"
                    />
                  </div>
                  <div class="col-lg-1">
                    <div class="form-group has-label">
                      <button
                      @click="
                      () => {
                        modal.delete.show = true;
                        modal.delete.terminalIndex = terminalIndex;
                                    // removeTerminal(terminalIndex, $event);
                                  }
                                  "
                                  type="button"
                                  class="btn base-button btn-danger w-100"
                                  :disabled="loading"
                                  >
                                  <i class="fa fa-trash"></i>
                                </button>
                              </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                              <base-input
                              required
                              placeholder="Loading/discharging rate"
                              :errors="
                              errors['terminais.' + terminalIndex + '.rate']
                              "
                              v-model="terminal.rate"
                              />
                            </div>
                            <div class="col-lg-6 mb-2">
                              <base-input
                              alternative=""
                              required
                              placeholder="Av. w/d / b.w"
                              :errors="
                              errors[
                              'terminais.' + terminalIndex + '.av_dw_bw'
                              ]
                              "
                              v-model="terminal.av_dw_bw"
                              />
                            </div>
                            <div class="col-lg-4">
                              <base-input
                              alternative=""
                              required
                              placeholder="Max draft"
                              :errors="
                              errors[
                              'terminais.' + terminalIndex + '.max_draft'
                              ]
                              "
                              v-model="terminal.max_draft"
                              />
                            </div>
                            <div class="col-lg-4">
                              <base-input
                              alternative=""
                              required
                              placeholder="Air Draft"
                              :errors="
                              errors[
                              'terminais.' + terminalIndex + '.draft_berth'
                              ]
                              "
                              v-model="terminal.draft_berth"
                              />
                            </div>
                            <div class="col-lg-4">
                              <base-input
                              alternative=""
                              required
                              placeholder="Loa"
                              :errors="
                              errors['terminais.' + terminalIndex + '.loa']
                              "
                              v-model="terminal.loa"
                              />
                            </div>
                            <div class="col-lg-12 my-2">
                              <base-input
                              alternative=""
                              required
                              placeholder="Remark"
                              :errors="
                              errors['terminais.' + terminalIndex + '.remark']
                              "
                              v-model="terminal.remark"
                              />
                            </div>
                             <div class="col-lg-12">
                                  <multiple-select
                                  multiple
                                  inputClasses="input"
                                  :close-on-select="false"
                                  :clear-on-select="false"
                                  placeholder="Comms of the terminal"
                                  :options="cargasTipos"
                                  optionsValue="name"
                                  v-model="terminal.cargas_ids"
                                  />
                                </div>
                          </div>
                        </div>
                        <div class="card-body pt-0">

                          <div class="card pt-0">
                            <div class="card-header pb-0">
                              <h3 class="">
                                Berths of this terminal
                                <base-button
                                
                                class="submit btn-side"
 
 
                                @click="addBerco(terminal)"
                                >
                                <i class="fas fa-plus mr-2"></i>
                                <span class="btn-inner--text">Add berth</span>
                              </base-button>
                            </h3>
                          </div>
                          <div class="card-body pb-0">
                            <div
                            class="form-row"
                            v-for="(berco, bercoIndex) in terminal.bercos"
                            >
                            <div class="col-lg-11">
                              <base-input
                              alternative=""
                              required
                              placeholder="berth name"
                              :errors="
                              errors[
                              'terminais.' +
                              terminalIndex +
                              '.bercos.' +
                              bercoIndex +
                              '.name'
                              ]
                              "
                              v-model="berco.name"
                              />
                            </div>
                            <div class="col-lg-1">
                              <div class="form-group has-label">
                                <button
                                type="submit "
                                class="btn base-button btn-danger w-100 redondo"
                                :disabled="loading"
                                @click="removeBerco(terminal, bercoIndex)"
                                >
                                <i class="fa fa-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>


  

    </div>
  </div>
</div>
</div>
</div>

<div class="row">
  <div class="col-12 btn-final txt-right">
    <input type="submit" class="submit-branco" value="See listing" @click="$router.push('/portos/listar')">

    <button type="submit" class="submit" value="Save"    :disabled="loading"
          @click="submit()"><i class="fa fa-spinner fa-spin" v-if="loading" /> Save </button>
  </div>
</div>
<modal :show="modal.delete.show" @close="closeDeleteModal()">
  <template v-slot:header>Delete</template>
    <template>
  Do you really want to delete this terminal ?
    </template>
    <template v-slot:footer >   
  <button
  class="submit "
  @click="removeTerminal(modal.delete.terminalIndex, $event)"
  >
  Yes
</button>
<button
class="submit-verde"
@click="
() => {
  closeDeleteModal();
  modal.delete.terminalIndex = null;
}
"
>
No
</button>

 </template>
</modal>
</div>
</template>
<script>
  import Service from "@/services/Portos";
  import CargasTiposService from "@/services/CargasTipos";
  export default {
    name: "usuario_form",
    created() {
      if (this.$route.params.id) {
        this.model.id = this.$route.params.id;
        this.find();
      }
      CargasTiposService.get({}).then((response) => {
        this.cargasTipos = response.data;
      });
    },
    data() {
      return {
        errors: {},
        loading: false,
        Service,
        cargasTipos: [],
        model: {
          nome: "",
          sigla: "",
          terminais: [],
          id: null,
        },
        modal: {
          delete: {
            show: false,
            terminalIndex: null,
          },
        },
      };
    },
    methods: {
      closeDeleteModal() {
        this.modal.delete.show = false;
      },
      addTerminal() {
        this.model.terminais.unshift({
          id: null,
          name: "",
          porto_id: this.model.id,
          bercos: [],
          cargas_ids: [],
        });
      },
      removeTerminal(index) {
        this.model.terminais.splice(index, 1);
        this.$notify({
          type: "info",
          message:
          "Terminal removed. This change will only take effect after saving the port.",
          horizontalAlign: "center",
        });
        this.modal.delete.show = false;
      },
      removeBerco(terminal, index) {
        terminal.bercos.splice(index, 1);
        this.$notify({
          type: "info",
          message:
          "Berth removed. This change will only take effect after saving the port",
          horizontalAlign: "center",
        });
      },
      addBerco(terminal) {
        terminal.bercos.unshift({
          id: null,
          name: "",
          terminal_id: terminal.id,
          bercos: [],
        });
      },
      find() {
        this.loading = true;
        this.errors = {};
        Service.find(this.model.id, {
          with: "terminais.bercos",
          terminais_cargas: true,
        })
        .then((response) => {
          this.model = response.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
      },
      submit() {
        this.loading = true;
        this.errors = {};
        Service.submit({ ...this.model, terminais_cargas: true })
        .then((response) => {
          this.model = response.data;
          this.loading = false;
          this.$notify({
            type: "success",
            message: "Port saved successfully!",
            horizontalAlign: "center",
          });
        })
        .catch((err) => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            let errors = err.response.data.errors;
            this.errors = errors;
            this.$notify({
              type: "danger",
              message: "Please check the form",
              horizontalAlign: "center",
            });
          }
        });
      },
    },
  };
</script>
<style></style>
