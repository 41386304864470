<template>
 <div class="main">

  <div class="row infos">
    <div class="col-xl-4 col-lg-12">
      <div class="blc-dados">
        <img src="/img/icon-port-item.png" alt="icone porto">
        <p>List Ports</p>
      </div>
    </div>
  </div>


    <div class="fundo">
      <div class="blc-inf-geral">
        <div class="blc-item">
          <div class="row">
            <div class="col-md-6 col-12">
              <h2>List</h2>
            </div>

            <div class="col-md-6 col-12 txt-right">
              <button class="submit-verde"  @click="cleanFiltros()">Clear Filters <img src="/img/icon-filter.png"></button>
              <button class="submit"  @click="$router.push('/portos/adicionar')">New Port +</button>
            </div>
          </div>
          <form>
          <div class="row">
            <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
             <base-input
             alternative=""
             label="Port"
             placeholder="search by port"
             input-classes="form-control-alternative"
             v-model.lazy="search.name"
             />
           </div>

           <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
            
              <base-input
                alternative=""
                label="Terminal"
                placeholder="search by terminal"
                input-classes="form-control-alternative"
                v-model.lazy="search.terminal"
              />
          </div>

          <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
              <base-input
                alternative=""
                label="Berth"
                placeholder="search by berth"
                input-classes="form-control-alternative"
                v-model.lazy="search.berco"
              />
          </div>

          <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
            <button style="margin-top: 32px;" class="submit w-100" value="Search"   :disabled="loading"
                  @click="listar()"> <i class="fa fa-spinner fa-spin" v-if="loading" /> Search </button>
          </div>

        </div>
        </form>

      </div>

      <div class="blc-tabela tabela-listagem  scroll">
        <table>
          <thead>
            <tr>
              <th>Port</th>
              <th>Order</th>
              <th>Terminals</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in list" :key="list.id">
              <td>   {{ row.name }}</td>
              <td>{{ row.ordem }}</td>
              <td>
                <span class="cartao" v-for="terminal in row.terminais" :key="terminal.id">  {{ terminal.name }}</span>
             
              </td>
              <td>   {{ row.created_at | date }}</td>
              <td class="text-right">
                  <base-dropdown class="dropdown" position="right">
                    <a 
                      slot="title"
                      class="btn btn-sm btn-icon-only "
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-ellipsis-v"></i>
                    </a>

                    <template>
                      <router-link
                        class="dropdown-item"
                        :to="'/portos/editar/' + row.id"
                        ><i class="fas fa-edit"></i> edit</router-link
                      >
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="
                          $root.openExcluirModal(
                            row,
                            'Would you like to delete the port ' +
                              row.name +
                              '?',
                            Service
                          )
                        "
                        ><i class="fas fa-trash" style="min-width: 20px"></i>
                        delete</a
                      >
                    </template>
                  </base-dropdown>
                </td>
            </tr>
             <tr v-if="!list.length && !loading">
                <td colspan="100" style="text-align: center">No port found</td>
              </tr>
              <tr v-if="loading">
                <td colspan="100" style="text-align: center">
                  <i
                    class="fa fa-spinner fa-spin"
                    v-if="loading"
                    style="font-size: 20px"
                  ></i>
                </td>
              </tr>
        
          </tbody>
        </table>
      </div>
    </div>
  </div>

     <base-pagination
            :pagination="pagination"
            @change="listar()"
            align="center"
          ></base-pagination>


    <excluir-modal
      :show="$root.modal.excluir.isOpen"
      :mensagem="$root.modal.excluir.mensagem"
      :model="$root.modal.excluir.model"
      :service="$root.modal.excluir.service"
      @close="handleExcluir"
    ></excluir-modal>
</div>
</template>
<script>
  import Service from "@/services/Portos";

  export default {
    data() {
      return {
        Service,
        loading: false,
        pagination: {
          page: 1,
          last_page: 1,
          per_page: 20,
          total: 1,
        },
        search: {
          name: "",
          sigla: "",
          terminal: "",
          berco: "",
        },
        defaultSearch: {},
        orderBy: {
          campo: "name",
          ordem: "asc",
        },
        orderBy: {
          campo: "name",
          ordem: "asc",
        },
        list: [
        // {
        //   name: "Admin",
        //   email: "admin@jsonapi.com",
        //   created_at: "2020-01-01",
        // },
        ],
      };
    },
    created() {
      this.defaultSearch = { ...this.search };
      this.listar();
    },
    watch: {
    // search: {
    //   deep: true,
    //   handler() {
    //     this.pagination.page = 1;
    //     this.listar();
    //   },
    // },
    orderBy: {
      deep: true,
      handler() {
        this.pagination.page = 1;
        this.listar();
      },
    },
  },
  methods: {
    handleExcluir(updated) {
      this.$root.modal.excluir.isOpen = false;
      if (updated) this.listar();
    },
    cleanFiltros() {
      this.search = { ...this.defaultSearch };
      this.listar();
    },
    listar() {
      this.loading = true;
      this.list = [];
      Service.get({
        with: ["terminais.bercos"],
        ...this.pagination,
        ...this.search,
      }).then((response) => {
        this.list = response.data.data;
        this.pagination.last_page = response.data.last_page;
        this.pagination.total = response.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style></style>
