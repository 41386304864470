var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row blc-descracao mb-0 pb-0"},[(!_vm.$root.isClient && _vm.userCanEdit())?_c('div',{staticClass:"col-12"},[_c('button',{class:("" + (_vm.edit ? 'submit-vermelho' : 'submit-verde')),on:{"click":function($event){return _vm.toggleEdit()}}},[_c('i',{class:("fa fa-" + (_vm.edit ? 'ban' : 'edit'))}),_vm._v(" "+_vm._s(_vm.edit ? "Cancel edit" : "Edit lines")+" ")]),(!_vm.edit)?_c('button',{staticClass:"submit",on:{"click":function($event){return _vm.$emit('newLineup')}}},[_vm._v(" New line + ")]):_vm._e(),(_vm.edit)?_c('button',{staticClass:"submit",on:{"click":function($event){return _vm.addLine()}}},[_vm._v(" New line + ")]):_vm._e(),(_vm.edit)?_c('button',{staticClass:"submit",on:{"click":function () {
        _vm.list.push({
          terminal_id: _vm.terminal.id,
          porto_id: _vm.terminal.porto_id,
          status: 'Expected',
          is_observacao: 1,
          observacao: null,
          cargas_afretadores: [],
          etb: null,
          eta: null,
          etc: null,
          navio_id: null,
          afretador_id: null,
        });
      }}},[_vm._v(" Add note ")]):_vm._e(),(_vm.edit)?_c('button',{staticClass:"submit float-right",staticStyle:{"margin-top":"12px"},attrs:{"disabled":_vm.rowloading},on:{"click":function($event){return _vm.saveList()}}},[(_vm.rowloading)?_c('i',{staticClass:"fa fa-spinner fa-spin",staticStyle:{"font-size":"20px"}}):_c('i',[_vm._v("Save")])]):_vm._e()]):_vm._e()]),_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll"}],staticClass:"blc-tabela zebrada scroll padding-table",class:{'padding-table' :_vm.edit || _vm.inEdit}},[_c('table',{staticClass:"zebrada",attrs:{"vue-dragscroll":""}},[_c('thead',{staticStyle:{"position":"initial"}},[(_vm.list.length)?_c('tr',[_c('th',[_vm._v("Vessel")]),_c('th',[_vm._v("Status")]),_c('th',{staticStyle:{"max-width":"100px"}},[_vm._v("QTTY")]),_c('th',[_vm._v("Comm")]),_c('th',[_vm._v(" ETA "),(_vm.edit)?[_c('i',{staticClass:"fa",class:_vm.lock_eta ? 'fa-lock' : 'fa-lock-open',attrs:{"title":"Lock year?"},on:{"click":function($event){_vm.lock_eta = _vm.lock_eta ? false : true}}})]:_vm._e()],2),_c('th',[_vm._v(" ETB "),(_vm.edit)?[_c('i',{staticClass:"fa",class:_vm.lock_etb ? 'fa-lock' : 'fa-lock-open',attrs:{"title":"Lock year?"},on:{"click":function($event){_vm.lock_etb = _vm.lock_etb ? false : true}}})]:_vm._e()],2),_c('th',[_vm._v(" ETC "),(_vm.edit)?[_c('i',{staticClass:"fa",class:_vm.lock_etc ? 'fa-lock' : 'fa-lock-open',attrs:{"title":"Lock year?"},on:{"click":function($event){_vm.lock_etc = _vm.lock_etc ? false : true}}})]:_vm._e()],2),_c('th',[_vm._v("Charter")]),(_vm.orderList && _vm.orderList[0].carga_tipo.name =='FERTILIZER')?_c('th',[_vm._v("POL")]):_c('th',[_vm._v("Disport")]),(!_vm.$root.isClient)?_c('th',[_vm._v("Agency")]):_vm._e(),(!_vm.$root.isClient)?_c('th',[_vm._v("Naabsa client")]):_vm._e(),_c('th')]):_vm._e()]),_c('tbody',{staticClass:"list"},[_vm._l((_vm.orderList),function(row,index){return [_c('tr',{class:row.is_observacao && !row.isInEdit && !_vm.edit
        ? 'observation-row'
        : '',style:(!_vm.$root.isClient ? _vm.getRowStyleByClientType(row) : '')},[(row.is_observacao)?[_c('td',{attrs:{"colspan":"5"}},[(!row.isInEdit && !_vm.edit)?[_vm._v(" "+_vm._s(row.observacao)+" ")]:[_c('base-input',{attrs:{"errors":_vm.errors[("list." + index + ".observacao")]},model:{value:(row.observacao),callback:function ($$v) {_vm.$set(row, "observacao", $$v)},expression:"row.observacao"}})]],2)]:[_c('td',[(row.isInEdit == false && !_vm.edit)?[_vm._v(" "+_vm._s(row.navio.name)+" "),(row.call_number)?_c('b',[_vm._v(" *"+_vm._s(row.call_number + (row.call_number > 1 ? "ND" : "ST"))+" CALL ")]):_vm._e()]:[_c('multiple-select',{attrs:{"optionsValue":"name_with_imo","disabled":!_vm.navios.length,"errors":_vm.errors[("list." + index + ".navio_id")],"addonLeftIcon":"fa fa-plus","inputClasses":"input table-multiselect","options":_vm.navios,"selectLabel":"","selectedLabel":"","placeholder":"select the vessel","deselectLabel":""},on:{"btn":function () {
                _vm.rowIndex = index;
                _vm.openModalCreate('navio');
              }},model:{value:(row.navio_id),callback:function ($$v) {_vm.$set(row, "navio_id", $$v)},expression:"row.navio_id"}})]],2),_c('td',{class:!_vm.$root.isClient ? _vm.getClass(row) : ''},[(row.isInEdit == false && !_vm.edit)?[_c('span',{style:(_vm.getStatusStyle(row.status))},[_vm._v(" "+_vm._s(row.status.replace( "hh:mm", (row.pob ? row.pob.substring(0, row.pob.length - 3) : "") + " " ))+" ")])]:[_c('base-select',{attrs:{"errors":_vm.errors[("list." + index + ".status")],"placeholder":"Select the status","options-key":"name","options":_vm.Service.status(),"required":""},on:{"change":function($event){return _vm.setStatus(row)}},model:{value:(row.status),callback:function ($$v) {_vm.$set(row, "status", $$v)},expression:"row.status"}}),(row.status == 'POB hh:mm lt')?_c('base-input',{attrs:{"type":"time"},model:{value:(row.pob),callback:function ($$v) {_vm.$set(row, "pob", $$v)},expression:"row.pob"}}):_vm._e()]],2),_c('td',[(row.isInEdit == false && !_vm.edit)?[_vm._v(" "+_vm._s(_vm.formatQty(row.qty))+" ")]:[_c('money',_vm._b({staticClass:"input",model:{value:(row.qty),callback:function ($$v) {_vm.$set(row, "qty", $$v)},expression:"row.qty"}},'money',_vm.$root.qtd,false)),_vm._l((_vm.errors[("list." + index + ".qty")]),function(erro){return (_vm.errors[("list." + index + ".qty")])?_c('div',{staticClass:"text-danger invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(erro)+" ")]):_vm._e()})]],2),_c('td',[(!row.isInEdit && !_vm.edit)?[_c('span',{style:(("color: " + (row.carga ? row.carga.color : '#000000') + ";"))},[_vm._v(" "+_vm._s(row.carga && row.carga.name))])]:[_c('multiple-select',{attrs:{"optionsValue":"name_with_synonym","disabled":!_vm.cargas.length,"errors":_vm.errors[("list." + index + ".carga_id")],"addonLeftIcon":"fa fa-plus","required":"","options":_vm.cargas.filter(function (c) { return _vm.carga_tipo_id ? c.tipo_id == _vm.carga_tipo_id : true; }
                ),"placeholder":"select the comm","inputClasses":"input table-multiselect","selectLabel":"","selectedLabel":"","formGroup":false,"deselectLabel":""},on:{"btn":function () {
                  _vm.rowIndex = index;
                  _vm.openModalCreate('carga');
                },"change":function($event){return _vm.setCargaTipo(row)}},model:{value:(row.carga_id),callback:function ($$v) {_vm.$set(row, "carga_id", $$v)},expression:"row.carga_id"}})]],2)],(!row.is_observacao)?_c('td',{class:!_vm.$root.isClient ? _vm.getClass(row) : ''},[(row.isInEdit == false && !_vm.edit)?[_vm._v(" "+_vm._s(row.eta ? _vm.formatDate(row.eta) : "TBI")+" ")]:[_c('base-input-date',{attrs:{"inputClasses":"input","data-no-dragscroll":"","locked":_vm.lock_eta,"placeholder":"TBI","errors":_vm.errors[("list." + index + ".eta")]},model:{value:(row.eta),callback:function ($$v) {_vm.$set(row, "eta", $$v)},expression:"row.eta"}})]],2):_vm._e(),_c('td',[(row.isInEdit == false && !_vm.edit)?[_vm._v(" "+_vm._s(row.etb ? _vm.formatDate(row.etb) : "TBI")+" ")]:[_c('base-input-date',{attrs:{"locked":_vm.lock_etb,"errors":_vm.errors[("list." + index + ".etb")],"inputClasses":"input","data-no-dragscroll":"","max":"9999-12-31","placeholder":"TBI","handleTab":function () {
              _vm.targetRow = row;
              _vm.focusTargetEtc();
            }},model:{value:(row.etb),callback:function ($$v) {_vm.$set(row, "etb", $$v)},expression:"row.etb"}})]],2),_c('td',[(row.isInEdit == false && !_vm.edit)?[_vm._v(" "+_vm._s(row.etc ? _vm.formatDate(row.etc) : "TBI")+" ")]:[_c('span',{ref:_vm.targetRow == row ? 'currentRowEtc' : '',refInFor:true},[_c('base-input-date',{attrs:{"inputClasses":"input","locked":_vm.lock_etc,"errors":_vm.errors[("list." + index + ".etc")],"data-no-dragscroll":"","placeholder":"TBI","max":"9999-12-31"},model:{value:(row.etc),callback:function ($$v) {_vm.$set(row, "etc", $$v)},expression:"row.etc"}})],1)]],2),_c('td',[(!row.isInEdit && !_vm.edit)?[_vm._v(" "+_vm._s(row.is_observacao ? "-" : row.afretador ? row.afretador.name : "")+" ")]:(!row.is_observacao)?[_c('multiple-select',{attrs:{"errors":_vm.errors[("list." + index + ".afretador_id")],"options":_vm.afretadores,"optionsValue":"name","addonLeftIcon":"fa fa-plus","required":"","inputClasses":"input table-multiselect","selectLabel":"","selectedLabel":"","formGroup":false,"placeholder":"select the charter","deselectLabel":""},on:{"btn":function () {
              _vm.rowIndex = index;
              _vm.openModalCreate('afretador');
            }},model:{value:(row.afretador_id),callback:function ($$v) {_vm.$set(row, "afretador_id", $$v)},expression:"row.afretador_id"}})]:_vm._e()],2),_c('td',[(!row.isInEdit && !_vm.edit)?[_vm._v(" "+_vm._s(row.is_observacao ? "-" : row.destination_porto ? row.destination_port.name || row.destination_port.pais : "")+" ")]:(!row.is_observacao)?[_c('multiple-select',{attrs:{"errors":_vm.errors[("list." + index + ".destination_porto")],"options":_vm.lineupPortos,"optionsValue":"port_name","addonLeftIcon":"fa fa-plus","required":"","placeholder":"select the disport","inputClasses":"input table-multiselect","selectLabel":"","selectedLabel":"","formGroup":false,"deselectLabel":""},on:{"btn":function () {
              _vm.rowIndex = index;
              _vm.openModalCreate('lineup_porto', 'destination_porto');
            }},model:{value:(row.destination_porto),callback:function ($$v) {_vm.$set(row, "destination_porto", $$v)},expression:"row.destination_porto"}})]:_vm._e()],2),(!_vm.$root.isClient)?_c('td',[(!row.isInEdit && !_vm.edit)?[_vm._v(" "+_vm._s(row.agencia && row.agencia.name)+" ")]:(!row.is_observacao)?[_c('multiple-select',{ref:"agencias",refInFor:true,attrs:{"disabled":!_vm.agencias.length,"errors":_vm.errors[("list." + index + ".agencia_id")],"placeholder":"Select the agency","options":_vm.agencias,"addonLeftIcon":"fa fa-plus","inputClasses":"input table-multiselect","selectLabel":"","selectedLabel":"","formGroup":false,"deselectLabel":""},on:{"btn":function () {
              _vm.rowIndex = index;
              _vm.openModalCreate('agencia');
            }},model:{value:(row.agencia_id),callback:function ($$v) {_vm.$set(row, "agencia_id", $$v)},expression:"row.agencia_id"}})]:_vm._e()],2):_vm._e(),(!_vm.$root.isClient)?_c('td',[(!row.isInEdit && !_vm.edit)?[_vm._v(" "+_vm._s(row.client_type)+" ")]:(!row.is_observacao)?[_c('base-select',{attrs:{"errors":_vm.errors[("list." + index + ".client_type")],"placeholder":"Select the client type","options-key":"name","options":_vm.Service.clientTypes()},model:{value:(row.client_type),callback:function ($$v) {_vm.$set(row, "client_type", $$v)},expression:"row.client_type"}})]:_vm._e()],2):_vm._e(),_c('td',{staticClass:"text-right"},[(row.isInEdit && !_vm.rowloading && !_vm.edit)?_c('button',{staticClass:"btn btn-primary redondo p-1 mt-1",on:{"click":function($event){return _vm.saveRow(row)}}},[_vm._v(" save ")]):_vm._e(),(row.isInEdit && !_vm.rowloading && !_vm.edit)?_c('button',{staticClass:"btn btn-secondary redondo p-1",on:{"click":function($event){return _vm.cancelEditLine(row)}}},[_vm._v(" cancel ")]):_vm._e(),_c('div',{staticClass:"btn-group"},[(_vm.edit)?_c('button',{staticClass:"btn btn-sm redondo btn-danger",on:{"click":function($event){return _vm.deleteLine(row)}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e(),(
      (_vm.edit || row.isInEdit) &&
      !row.is_observacao &&
      row.cargas_afretadores &&
      row.cargas_afretadores.length == 0
      )?_c('button',{staticClass:"ml-1 btn btn-sm redondo btn-primary",on:{"click":function($event){return _vm.addCargaAfretador(row)}}},[_c('i',{staticClass:"fas fa-plus"})]):_vm._e()]),(_vm.rowloading)?_c('i',{staticClass:"fa fa-spinner fa-spin",staticStyle:{"font-size":"20px"}}):_vm._e(),(!_vm.$root.isClient && !_vm.edit && !_vm.inEdit && !row.isInEdit && _vm.userCanEdit())?_c('base-dropdown',{staticClass:"dropdown",attrs:{"position":"right"}},[_c('a',{staticClass:"btn btn-sm btn-icon-only",staticStyle:{"height":"23px","margin-top":"-30px"},attrs:{"slot":"title","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"},slot:"title"},[_c('i',{staticClass:"fas fa-ellipsis-v"})]),[(row.status != 'Sailed' && !_vm.edit)?_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.setEditLine(row)}}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" edit in line ")]):_vm._e(),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$emit('openModal', row)}}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" edit in modal ")]),(!row.is_observacao)?_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$emit('openModal', Object.assign({}, row,
  {id:null}))}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" add copy ")]):_vm._e(),(!row.is_observacao)?_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$emit('openModal', Object.assign({}, row,
  {call_in_id: row.id}))}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" add call ")]):_vm._e(),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$root.openExcluirModal(
  row,
  'Would you like to delete the lineup?',
  _vm.Service
  )}}},[_c('i',{staticClass:"fas fa-trash",staticStyle:{"min-width":"20px"}}),_vm._v(" remove")])]],2):_vm._e()],1)],2),_vm._l((row.cargas_afretadores),function(carga_afretador,i){return _c('tr',{style:(!_vm.$root.isClient ? _vm.getRowStyleByClientType(row) : '')},[_c('td',{attrs:{"colspan":"2"}}),_c('td',[(!_vm.edit)?[_vm._v(" "+_vm._s(_vm.formatQty(carga_afretador.qty))+" ")]:[_c('money',_vm._b({staticClass:"input",model:{value:(carga_afretador.qty),callback:function ($$v) {_vm.$set(carga_afretador, "qty", $$v)},expression:"carga_afretador.qty"}},'money',_vm.$root.qtd,false)),_vm._l((_vm.errors[
      ("list." + index + ".cargas_afretadores." + i + ".qty")
      ]),function(erro){return (_vm.errors[("list." + index + ".cargas_afretadores." + i + ".qty")])?_c('div',{staticClass:"text-danger invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(erro)+" ")]):_vm._e()})]],2),_c('td',[(!_vm.edit)?[_vm._v(" "+_vm._s(carga_afretador.carga_id ? _vm.getValue("cargas", carga_afretador.carga_id) : "")+" ")]:[_c('multiple-select',{attrs:{"errors":_vm.errors[("list." + index + ".cargas_afretadores." + i + ".carga_id")],"options":_vm.cargas,"optionsValue":"name","addonLeftIcon":"fa fa-plus","required":"","inputClasses":"input table-multiselect","selectLabel":"","selectedLabel":"","formGroup":false,"placeholder":"select the comm","deselectLabel":""},on:{"btn":function () {
      _vm.rowIndex = index;
      _vm.openModalCreate('afretador');
    }},model:{value:(carga_afretador.carga_id),callback:function ($$v) {_vm.$set(carga_afretador, "carga_id", $$v)},expression:"carga_afretador.carga_id"}})]],2),_c('td',{attrs:{"colspan":"3"}}),_c('td',[(!_vm.edit)?[_vm._v(" "+_vm._s(carga_afretador.afretador_id ? _vm.getValue("afretadores", carga_afretador.afretador_id) : "")+" ")]:[_c('multiple-select',{attrs:{"errors":_vm.errors[
    ("list." + index + ".cargas_afretadores." + i + ".afretador_id")
    ],"options":_vm.afretadores,"optionsValue":"name","addonLeftIcon":"fa fa-plus","required":"","inputClasses":"input table-multiselect","selectLabel":"","selectedLabel":"","formGroup":false,"placeholder":"select the charter","deselectLabel":""},on:{"btn":function () {
      _vm.rowIndex = index;
      _vm.openModalCreate('afretador');
    }},model:{value:(carga_afretador.afretador_id),callback:function ($$v) {_vm.$set(carga_afretador, "afretador_id", $$v)},expression:"carga_afretador.afretador_id"}})]],2),_c('td',{attrs:{"colspan":"3"}}),_c('td',{staticStyle:{"text-align":"right"},attrs:{"coltemplate":"2"}},[_c('div',{staticClass:"btn-group"},[(_vm.edit && !_vm.$root.isClient)?_c('button',{staticClass:"btn btn-sm btn-danger redondo",on:{"click":function($event){return _vm.removeCargaAfretador(row, carga_afretador)}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e(),(
  (_vm.edit || row.isInEdit) &&
  row.cargas_afretadores.length == i + 1 &&
  !_vm.$root.isClient
  )?_c('button',{staticClass:"ml-1 btn btn-sm btn-primary redondo",on:{"click":function($event){return _vm.addCargaAfretador(row)}}},[_c('i',{staticClass:"fas fa-plus"})]):_vm._e()])])])})]}),(!_vm.list.length && !_vm.loading)?_c('tr',[_c('td',{staticStyle:{"text-align":"center"},attrs:{"coltemplate":"100"}},[_vm._v(" no vessel's to call ")])]):_vm._e(),(_vm.loading)?_c('tr',[_c('td',{staticStyle:{"text-align":"center"},attrs:{"coltemplate":"100"}},[(_vm.loading)?_c('i',{staticClass:"fa fa-spinner fa-spin",staticStyle:{"font-size":"20px"}}):_vm._e()])]):_vm._e()],2)])]),_c('modal',{attrs:{"show":_vm.modal.create.show},on:{"close":function () {
  _vm.modal.create.show = false;
}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Add new ")]},proxy:true}])},[[(_vm.modal.create.show)?_c('generic-form',{attrs:{"service":_vm.modal.create.service,"type":_vm.modal.create.type,"params":_vm.modal.create.params},on:{"success":_vm.handleModalForm}}):_vm._e()]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }